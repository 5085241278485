<template>
  <b-row class="d-flex align-items-start">
    <b-col class="col-12 col-md-2 col-lg-2 col-xl-2">
      <input-select-search
        :label="$t('ACADEMICO.ANO_LETIVO')"
        ref="periodoLetivo"
        :options="opcoes.anoLetivo"
        v-model="anoLetivoId"
        required
      />
    </b-col>
    <div class="col-12 col-md-auto align-items-center">
      <b-button
        variant="primary"
        @click="processarSegmentacoes"
        class="botao-acao-filtro mt-3 w-100"
      >
        {{ this.$t('ACADEMICO.PROCESSAR_PERIODO') }}
      </b-button>
    </div>
    <div class="col-12 col-md-auto align-items-center">
      <b-button
        variant="info"
        @click="encerrarPeriodo"
        class="botao-acao-filtro w-100 my-3"
      >
        {{ this.$t('ACADEMICO.ENCERRAR_PERIODO_LETIVO') }}
      </b-button>
    </div>
  </b-row>
</template>

<script>
// helpers & aux
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// services
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';

// components
import { InputSelectSearch } from '@/components/inputs';
export default {
  components: {
    InputSelectSearch,
  },
  data() {
    return {
      anoLetivoId: '',
      opcoes: {
        anoLetivo: [],
      },
    };
  },
  mounted() {
    this.getAnosLetivos();
  },
  watch: {
    anoLetivoId: {
      handler(v) {
        this.$emit('anoLetivo', v);
      },
    },
  },
  methods: {
    processarSegmentacoes() {
      if (!this.validarFormulario()) return;
      this.$emit('processar-segmentacao');
    },
    encerrarPeriodo() {
      if (!this.validarFormulario()) return;
      this.$emit('encerrar-periodo');
    },
    getAnosLetivos() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.ano,
            value: row.id,
          }));
          this.opcoes.anoLetivo = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
  },
};
</script>
