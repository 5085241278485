<template>
  <topbar
    :titulo="$t('ACADEMICO_GERENCIAMENTO.FECHAMENTO_PERIODO')"
    :subtitulo="$t('ACADEMICO.FECHAMENTO_PERIODO_LETIVO_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  />
</template>
<script>
// Utils & Aux:
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
        { titulo: this.$t('ACADEMICO_GERENCIAMENTO.FECHAMENTO_PERIODO') },
      ],
    };
  },
  methods: {
    // FUNÇÕES CRUD:
    cadastrar() {
      this.$emit('cadastrar', 'criar-periodo-letivo');
    },
  },
};
</script>
    