<template>
  <b-row>
    <b-col cols="12">
      <div class="mb-4">
        <titulo-fechamento-periodo-letivo />

        <filtro-fechamento-periodo-letivo
          @anoLetivo="setAnoLetivo"
          @processar-segmentacao="processarSegmentacoes"
          @encerrar-periodo="encerrarPeriodo"
        />

        <tabela-fechamento
          :items="segmentacoes"
          @fecharSegmento="fecharSegmento"
          @abrirSegmento="abrirSegmento"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
// Utils & Aux:
import VerificacaoPreenchimento from '@/components/mixins/verificacao-preenchimento';
import breadcrumb from '@/common/utils/breadcrumb';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import SegmentacaoService from '@/common/services/anoLetivo/segmentacao.service';

// Components:
import TabelaFechamento from './components/TabelaFechamento.vue';
import TituloFechamentoPeriodoLetivo from '@/views/periodos-letivos/fechamento-periodo/components/TituloFechamentoPeriodoLetivo';
import FiltroFechamentoPeriodoLetivo from '@/views/periodos-letivos/fechamento-periodo/components/FiltroFechamentoPeriodoLetivo';

export default {
  name: 'Fechamento',
  mixins: [VerificacaoPreenchimento],
  components: {
    TituloFechamentoPeriodoLetivo,
    FiltroFechamentoPeriodoLetivo,
    TabelaFechamento,
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
      { titulo: this.$t('ACADEMICO_GERENCIAMENTO.FECHAMENTO_PERIODO') },
    ]);
  },
  data() {
    return {
      anoLetivoId: '',
      segmentacoes: [],
    };
  },

  methods: {
    encerrarPeriodo() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.encerrarPeriodo(this.anoLetivoId)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.data);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    processarSegmentacoes() {
      this.$store.dispatch(START_LOADING);
      SegmentacaoService.buscarSegmentacoesPorAnoLetivoId(this.anoLetivoId)
        .then(({ data }) => {
          this.segmentacoes = data.data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    abrirSegmento(item) {
      this.$store.dispatch(START_LOADING);
      SegmentacaoService.abrirSegmento(item.id)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.data);
          this.processarSegmentacoes();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    fecharSegmento(item) {
      this.$store.dispatch(START_LOADING);
      SegmentacaoService.fecharSegmento(item.id)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.data);
          this.processarSegmentacoes();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    setAnoLetivo(anoLetivoId) {
      this.anoLetivoId = anoLetivoId;
    },
  },
};
</script>
