<template>
  <div>
    <b-table
      :fields="tabela.fields"
      :items="items"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      select-mode="single"
      ref="selectableTable"
      responsive="md"
      show-empty
      striped
      hover
    >
      <template #cell(acoes)="item">
        <div class="flex">
          <b-dropdown variant="outline-default">
            <b-dropdown-item
              v-if="item.item.statusAbertura !== 95"
              @click="fecharSegmentacao(item.item)"
            >
              {{ $t('ACADEMICO.FECHAR_SEGMENTACAO') }}
            </b-dropdown-item>

            <b-dropdown-item
              v-if="item.item.statusAbertura !== 94"
              @click="abrirSegmentacao(item.item)"
            >
              {{ $t('ACADEMICO.ABRIR_SEGMENTACAO') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import helpers from '@/common/utils/helpers';
export default {
  name: 'TabelaFechamento',
  props: {
    items: {
      type: Array,
      default: Array,
    },
  },

  data() {
    return {
      tabela: {
        fields: [
          {
            key: 'descricao',
            label: 'Descrição',
            sortable: true,
          },
          {
            key: 'statusAberturaDescricao',
            label: 'Status',
            sortable: true,
          },
          {
            key: 'lancamentoNota',
            label: 'Nota',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarBooleanSimNao(value);
            },
          },
          {
            key: 'lancamentoFrequencia',
            label: 'Frequência',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarBooleanSimNao(value);
            },
          },
          {
            key: 'lancamentoRecuperacao',
            label: 'Recuperação',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarBooleanSimNao(value);
            },
          },
          {
            key: 'dataInicio',
            label: 'Data início',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarDataBr(value);
            },
          },
          {
            key: 'dataFim',
            label: 'Data fim',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarDataBr(value);
            },
          },
          {
            key: 'acoes',
            label: 'Ações',
            sortable: true,
          },
        ],
      },
      selected: {},
    };
  },
  methods: {
    fecharSegmentacao(item) {
      this.$emit('fecharSegmento', item);
    },
    abrirSegmentacao(item) {
      this.$emit('abrirSegmento', item);
    },
  },
};
</script>
